<template>
  <div>
    <sidebar-component></sidebar-component>
    <div class="relative md:ml-64 bg-blueGray-100">
      <navbar-component></navbar-component>
      <!-- Header -->
      <div class="relative bg-blue-600 md:pt-32 pb-32 pt-12">
        <div class="rounded-t mb-0 px-4 py-3 border-0">
          <div
            class="container mx-auto my-12 h-auto flex flex-wrap justify-between items-start text-sm p-8 pt-8 pb-4 box-border border-4 border-gray-400 bg-gray-100"
          >
            <a class="text-2xl text-center text-black font-bold"
              >Solicitação : Alterar Saída (Venda)</a
            >
            <RouterLinkButton
              btnText=""
              color="sky"
              tipo="fas"
              icone="arrow-left"
              url="/solicitacao"
            />
            <div class="bg-green-500 w-full h-1"></div>
            <div class="mx-auto py-4">
              <div class="flex flex-wrap font-bold text-gray-100">
                <div class="w-full mt-4">
                  <div class="text-gray-900 text-xl">
                    Código : {{ id_solicitacao }}
                  </div>
                </div>
              </div>
            </div>
            <div class="bg-green-500 w-full h-1"></div>
            <TextInput
              label=""
              placeholder=""
              v-model:input="id_cliente"
              inputType="hidden"
              readonly
            />
            <a class="text-sky-400 font-bold"
              >Empresa : {{ id_cliente }}-{{ razaoSocial }}</a
            >
            <div>
              <button
                class="bg-gray-400 text-white active:bg-pink-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="button"
                v-on:click="toggleModal()"
              >
                Escolha a empresa que pertence a venda !
              </button>
              <div
                v-if="showModal"
                class="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex"
              >
                <div class="relative w-auto my-6 mx-auto max-w-3xl">
                  <!--content-->
                  <div
                    class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none"
                  >
                    <!--header-->
                    <div
                      class="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t"
                    >
                      <h3 class="text-3xl font-semibold">Empresa</h3>
                      <button
                        class="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                        v-on:click="toggleModal()"
                      >
                        <span
                          class="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none"
                        >
                          ×
                        </span>
                      </button>
                    </div>
                    <!--body-->
                    <div class="relative p-6 flex-auto">
                      <div class="flex mt-4 mb-8">
                        <div class="w-full md:w-1/2 px-3">
                          <TextInput
                            label="Digitar o nome da empresa"
                            placeholder="Pesquisar..."
                            v-model:input="searchCompany"
                            inputType="text"
                          />
                        </div>
                        <div class="w-full md:w-1/2 px-3">
                          <form
                            @submit.prevent="onSubmit"
                            @click="getCompanyList"
                          >
                            <LoadingButton
                              :isLoading="isLoading"
                              class="block w-full bg-green-500 text-white rounded-sm py-3 text-sm tracking-wide transform motion-safe:hover:-translate-y-1 text-blue-700 motion-safe:hover:scale-110 transition ease-in-out"
                            >
                              Pesquisar
                            </LoadingButton>
                          </form>
                        </div>
                        <div class="mx-auto py-4">
                          <table class="table-auto text-xs">
                            <thead>
                              <tr class="text-gray-600 bg-gray-300">
                                <th class="border px-4 py-2">Id</th>
                                <th class="border px-4 py-2">Empresa</th>
                                <th class="border px-4 py-2"></th>
                                <th class="border px-4 py-2">Ação</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                class="text-gray-500 bg-gray-100"
                                v-for="listCompany in listCompanies"
                                :key="listCompany"
                              >
                                <td>{{ listCompany.id }}</td>
                                <td>{{ listCompany.razaoSocial }}</td>
                                <td>{{ listCompany.nomeFantasia }}</td>
                                <td>
                                  <div
                                    class="block w-full bg-green-500 text-white rounded-sm py-3 text-sm tracking-wide transform motion-safe:hover:-translate-y-1 text-blue-700 motion-safe:hover:scale-110 transition ease-in-out"
                                  >
                                    <SubmitFormButton
                                      btnText="Escolha"
                                      @click="selectCompany(listCompany)"
                                    />
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          <div class="flex items-center justify-center p-2">
                            <v-pagination
                              v-model="pageCompanies"
                              :pages="pageCountCompanies"
                              :range-size="1"
                              active-color="#337aff"
                              @update:modelValue="listCompanies"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <vue-snotify />
                    <!--footer-->
                    <div
                      class="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b"
                    >
                      <button
                        class="text-red-500 bg-transparent border border-solid border-red-500 hover:bg-red-500 hover:text-white active:bg-red-600 font-bold uppercase text-sm px-6 py-3 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                        type="button"
                        v-on:click="toggleModal()"
                      >
                        Sair
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div
                v-if="showModal"
                class="opacity-25 fixed inset-0 z-40 bg-black"
              ></div>
            </div>
            <div class="bg-green-500 w-full h-1"></div>
            <TextInput
              label=""
              placeholder=""
              v-model:input="id_fornecedor"
              inputType="hidden"
              readonly
            />
            <a class="text-sky-400 font-bold"
              >Cliente : {{ id_fornecedor }}-{{ nome_fornecedor }}</a
            >
            <div>
              <button
                class="bg-gray-400 text-white active:bg-pink-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="button"
                v-on:click="toggleModalSupplier()"
              >
                Escolha o Cliente que pertence a venda !
              </button>
              <div
                v-if="showModalSupplier"
                class="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex"
              >
                <div class="relative w-auto my-6 mx-auto max-w-3xl">
                  <!--content-->
                  <div
                    class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none"
                  >
                    <!--header-->
                    <div
                      class="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t"
                    >
                      <h3 class="text-3xl font-semibold">Fornecedor</h3>
                      <button
                        class="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                        v-on:click="toggleModalSupplier()"
                      >
                        <span
                          class="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none"
                        >
                          ×
                        </span>
                      </button>
                    </div>
                    <!--body-->
                    <div class="relative p-6 flex-auto">
                      <div class="flex mt-4 mb-8">
                        <div class="w-full md:w-1/2 px-3">
                          <TextInput
                            label="Digitar o nome do fornecedor"
                            placeholder="Pesquisar..."
                            v-model:input="searchSupplier"
                            inputType="text"
                          />
                        </div>
                        <div class="w-full md:w-1/2 px-3">
                          <form
                            @submit.prevent="onSubmit"
                            @click="getSupplierList"
                          >
                            <LoadingButton
                              :isLoading="isLoading"
                              class="block w-full bg-green-500 text-white rounded-sm py-3 text-sm tracking-wide transform motion-safe:hover:-translate-y-1 text-blue-700 motion-safe:hover:scale-110 transition ease-in-out"
                            >
                              Pesquisar
                            </LoadingButton>
                          </form>
                        </div>
                        <div class="mx-auto py-4">
                          <table class="table-auto text-xs">
                            <thead>
                              <tr class="text-gray-600 bg-gray-300">
                                <th class="border px-4 py-2">Id</th>
                                <th class="border px-4 py-2">Fornecedor</th>
                                <th class="border px-4 py-2">Ação</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                class="text-gray-500 bg-gray-100"
                                v-for="listSupplier in listSuppliers"
                                :key="listSupplier"
                              >
                                <td>{{ listSupplier.id }}</td>
                                <td>{{ listSupplier.razaoSocial }}</td>
                                <td>
                                  <div
                                    class="block w-full bg-green-500 text-white rounded-sm py-3 text-sm tracking-wide transform motion-safe:hover:-translate-y-1 text-blue-700 motion-safe:hover:scale-110 transition ease-in-out"
                                  >
                                    <SubmitFormButton
                                      btnText="Escolha"
                                      @click="selectSupplier(listSupplier)"
                                    />
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          <div class="flex items-center justify-center p-2">
                            <v-pagination
                              v-model="pageSuppliers"
                              :pages="pageCountSuppliers"
                              :range-size="1"
                              active-color="#337aff"
                              @update:modelValue="listSuppliers"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <vue-snotify />
                    <!--footer-->
                    <div
                      class="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b"
                    >
                      <button
                        class="text-red-500 bg-transparent border border-solid border-red-500 hover:bg-red-500 hover:text-white active:bg-red-600 font-bold uppercase text-sm px-6 py-3 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                        type="button"
                        v-on:click="toggleModalSupplier()"
                      >
                        Sair
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div
                v-if="showModalSupplier"
                class="opacity-25 fixed inset-0 z-40 bg-black"
              ></div>
            </div>
            <div class="bg-green-500 w-full h-1"></div>
            <div class="w-full md:w-full px-3">
              <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
                <div
                  class="w-full border px-4 py-2 rounded focus:border-blue-500 focus:shadow-outline outline-none"
                >
                  <div class="w-full text-gray-900 md:w-full px-6">
                    <TextInputDate
                      label="Data emissão"
                      placeholder="Digitar emissão !"
                      v-model:input="dt_emissao"
                      inputType="text"
                    />
                  </div>
                  <div class="w-full text-gray-900 md:w-full px-6">
                    <TextInputDate
                      label="Data retirada"
                      placeholder="Digitar data retirada !"
                      v-model:input="dt_retirada"
                      inputType="text"
                    />
                  </div>
                  <div class="w-full text-gray-900 md:w-full px-6">
                    <TextInputDate
                      label="Data entrega"
                      placeholder="Digitar data entrega !"
                      v-model:input="dt_entrega"
                      inputType="text"
                    />
                  </div>
                  <div class="w-full text-gray-900 md:w-full px-6">
                    <TextInputMoeda
                      label="Vlr Solicitação"
                      placeholder="Valor total"
                      v-model:input="vlrtotal"
                      inputType="text"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="bg-green-500 w-full h-1"></div>
            <div class="flex flex-wrap mt-8 mb-6">
              <form @submit.prevent="onSubmit" @click="update">
                <LoadingButton
                  :isLoading="isLoading"
                  class="block w-full bg-green-500 text-white rounded-sm py-3 text-sm tracking-wide transform motion-safe:hover:-translate-y-1 text-blue-700 motion-safe:hover:scale-110 transition ease-in-out"
                >
                  Salvar
                </LoadingButton>
              </form>
              <vue-snotify />
            </div>
            <div class="bg-green-500 w-full h-1"></div>
            <div>
              <button
                class="bg-gray-500 text-white active:bg-pink-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="button"
                v-on:click="toggleModalProduct()"
              >
                Escolha o produto que pertence a entrada por descrição!
              </button>
              <div
                v-if="showModalProduct"
                class="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex"
              >
                <div class="relative w-auto my-6 mx-auto max-w-3xl">
                  <!--content-->
                  <div
                    class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none"
                  >
                    <!--header-->
                    <div
                      class="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t"
                    >
                      <h3 class="text-3xl font-semibold">Produto</h3>
                      <button
                        class="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                        v-on:click="toggleModalProduct()"
                      >
                        <span
                          class="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none"
                        >
                          ×
                        </span>
                      </button>
                    </div>
                    <!--body-->
                    <div class="relative p-6 flex-auto">
                      <div class="flex mt-4 mb-8">
                        <div class="w-full md:w-1/2 px-3">
                          <TextInput
                            label="Digitar o nome do produto"
                            placeholder="Pesquisar..."
                            v-model:input="searchStock"
                            inputType="text"
                          />
                        </div>
                        <div class="w-full md:w-1/2 px-3">
                          <form
                            @submit.prevent="onSubmit"
                            @click="getStockListProd"
                          >
                            <LoadingButton
                              :isLoading="isLoading"
                              class="block w-full bg-green-500 text-white rounded-sm py-3 text-sm tracking-wide transform motion-safe:hover:-translate-y-1 text-blue-700 motion-safe:hover:scale-110 transition ease-in-out"
                            >
                              Pesquisar
                            </LoadingButton>
                          </form>
                        </div>
                        <div class="mx-auto py-4">
                          <table class="table-auto text-xs">
                            <thead>
                              <tr class="text-gray-600 bg-gray-300">
                                <th class="border px-4 py-2">Cód barra</th>
                                <th class="border px-4 py-2">Produto</th>
                                <th class="border px-4 py-2">Estoque</th>
                                <th class="border px-4 py-2">Ação</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                class="text-gray-500 bg-gray-100"
                                v-for="listStock in listStocks"
                                :key="listStock"
                              >
                                <td>{{ listStock.codigo_barra }}</td>
                                <td>{{ listStock.nome_produto }}</td>
                                <td>{{ listStock.estoque }}</td>
                                <td>
                                  <div
                                    class="block w-full bg-green-500 text-white rounded-sm py-3 text-sm tracking-wide transform motion-safe:hover:-translate-y-1 text-blue-700 motion-safe:hover:scale-110 transition ease-in-out"
                                  >
                                    <SubmitFormButton
                                      btnText="Escolha"
                                      @click="selectProduct(listStock)"
                                    />
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          <div class="flex items-center justify-center p-2">
                            <v-pagination
                              v-model="pageStocks"
                              :pages="pageCountStocks"
                              :range-size="1"
                              active-color="#337aff"
                              @update:modelValue="listStocks"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <vue-snotify />
                    <!--footer-->
                    <div
                      class="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b"
                    >
                      <button
                        class="text-red-500 bg-transparent border border-solid border-red-500 hover:bg-red-500 hover:text-white active:bg-red-600 font-bold uppercase text-sm px-6 py-3 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                        type="button"
                        v-on:click="toggleModalProduct()"
                      >
                        Sair
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div
                v-if="showModalProduct"
                class="opacity-25 fixed inset-0 z-40 bg-black"
              ></div>
            </div>
            <div>
              <button
                class="bg-gray-400 text-white active:bg-pink-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="button"
                v-on:click="toggleModalProductCB()"
              >
                Escolha o produto que pertence a entrada por código de barra!
              </button>
              <div
                v-if="showModalProductCB"
                class="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex"
              >
                <div class="relative w-auto my-6 mx-auto max-w-3xl">
                  <!--content-->
                  <div
                    class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none"
                  >
                    <!--header-->
                    <div
                      class="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t"
                    >
                      <h3 class="text-3xl font-semibold">Produto</h3>
                      <button
                        class="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                        v-on:click="toggleModalProductCB()"
                      >
                        <span
                          class="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none"
                        >
                          ×
                        </span>
                      </button>
                    </div>
                    <!--body-->
                    <div class="relative p-6 flex-auto">
                      <div class="flex mt-4 mb-8">
                        <div class="w-full md:w-1/2 px-3">
                          <TextInput
                            label="Digitar o código de barra"
                            placeholder="Pesquisar..."
                            v-model:input="searchStockCB"
                            inputType="text"
                          />
                        </div>
                        <div class="w-full md:w-1/2 px-3">
                          <form
                            @submit.prevent="onSubmit"
                            @click="getStockListProdCB"
                          >
                            <LoadingButton
                              :isLoading="isLoading"
                              class="block w-full bg-green-500 text-white rounded-sm py-3 text-sm tracking-wide transform motion-safe:hover:-translate-y-1 text-blue-700 motion-safe:hover:scale-110 transition ease-in-out"
                            >
                              Pesquisar
                            </LoadingButton>
                          </form>
                        </div>
                        <div class="mx-auto py-4">
                          <table class="table-auto text-xs">
                            <thead>
                              <tr class="text-gray-600 bg-gray-300">
                                <th class="border px-4 py-2">Cód barra</th>
                                <th class="border px-4 py-2">Produto</th>
                                <th class="border px-4 py-2">Estoque</th>
                                <th class="border px-4 py-2">Ação</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                class="text-gray-500 bg-gray-100"
                                v-for="listStock in listStocks"
                                :key="listStock"
                              >
                                <td>{{ listStock.codigo_barra }}</td>
                                <td>{{ listStock.nome_produto }}</td>
                                <td>{{ listStock.estoque }}</td>
                                <td>
                                  <div
                                    class="block w-full bg-green-500 text-white rounded-sm py-3 text-sm tracking-wide transform motion-safe:hover:-translate-y-1 text-blue-700 motion-safe:hover:scale-110 transition ease-in-out"
                                  >
                                    <SubmitFormButton
                                      btnText="Escolha"
                                      @click="selectProduct(listStock)"
                                    />
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          <div class="flex items-center justify-center p-2">
                            <v-pagination
                              v-model="pageStocks"
                              :pages="pageCountStocks"
                              :range-size="1"
                              active-color="#337aff"
                              @update:modelValue="listStocks"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <vue-snotify />
                    <!--footer-->
                    <div
                      class="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b"
                    >
                      <button
                        class="text-red-500 bg-transparent border border-solid border-red-500 hover:bg-red-500 hover:text-white active:bg-red-600 font-bold uppercase text-sm px-6 py-3 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                        type="button"
                        v-on:click="toggleModalProductCB()"
                      >
                        Sair
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div
                v-if="showModalProductCB"
                class="opacity-25 fixed inset-0 z-40 bg-black"
              ></div>
            </div>

            <div
              class="w-full border px-4 py-2 rounded focus:border-blue-500 focus:shadow-outline outline-none"
            >
              <TextInput
                label=""
                placeholder=""
                v-model:input="id_produto"
                inputType="hidden"
                readonly
              />
              <a class="text-sky-400 font-bold"
                >Produto : {{ id_produto }}-{{ nome_produto }}</a
              >
            </div>
            <div
              class="w-full border px-4 py-2 rounded focus:border-blue-500 focus:shadow-outline outline-none"
            >
              <div class="w-full md:w-auto px-3">
                <TextInput
                  label="Código barra"
                  placeholder="Código barra"
                  v-model:input="codigo_barra"
                  inputType="hidden"
                />
                {{ codigo_barra }}
              </div>
              <div class="w-full md:w-auto px-3">
                <TextInput
                  label="Unidade"
                  placeholder="Unidade"
                  v-model:input="unidade"
                  inputType="hidden"
                />
                {{ unidade }}
              </div>
              <div class="w-full md:w-auto px-3">
                <TextInput
                  label="Valor unitário"
                  placeholder="Valor unitário"
                  v-model:input="vlrunitario"
                  inputType="text"
                />
              </div>
              <div class="w-full md:w-auto px-3">
                <TextInput
                  label="Quantidade"
                  placeholder="Quantidade"
                  v-model:input="quantidade"
                  inputType="text"
                />
                <SubmitFormButton
                  btnText="Qtd + Vlr Unitátio"
                  @click="multiplicarprod()"
                />
              </div>
              <div class="w-full text-gray-700 md:w-auto px-3">
                <TextInput
                  label="Valor total"
                  placeholder="Valor total"
                  v-model:input="vlrtotal_item"
                  inputType="hidden"
                />
                {{ vlrtotal_item | formatNumber }}
              </div>
              <div class="flex flex-wrap mt-8 mb-6">
                <div class="w-full md:w-auto px-6">
                  <form
                    @submit.prevent="onSubmit"
                    @click="registerItemSolicitacao"
                  >
                    <LoadingButton
                      :isLoading="isLoading"
                      class="block w-full bg-green-500 text-white rounded-sm py-3 text-sm tracking-wide transform motion-safe:hover:-translate-y-1 text-blue-700 motion-safe:hover:scale-110 transition ease-in-out"
                    >
                      Include(Incluir)
                    </LoadingButton>
                  </form>
                </div>
              </div>
            </div>

            <div class="bg-green-500 w-full h-1"></div>
            <div class="text-gray-600 text-xl">Itens da solicitação</div>
            <div class="bg-green-500 w-full h-1"></div>
            <table class="table-auto text-xs">
              <thead>
                <tr class="text-gray-600 bg-gray-300">
                  <th class="border px-4 py-2">ID</th>
                  <th class="border px-4 py-2">Código barras</th>
                  <th class="border px-4 py-2">Descrição</th>
                  <th class="border px-4 py-2">Quantidade</th>
                  <th class="border px-4 py-2">Vlr unitario</th>
                  <th class="border px-4 py-2">Vlr total</th>
                  <th class="border px-4 py-2">Ação</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  class="text-gray-500 bg-gray-100"
                  v-for="listSolicitItem in listSolicitItems"
                  :key="listSolicitItem"
                >
                  <td>{{ listSolicitItem.id }}</td>
                  <td>{{ listSolicitItem.codigo_barra }}</td>
                  <td>{{ listSolicitItem.nome_produto }}</td>
                  <td>{{ listSolicitItem.quantidade }}</td>
                  <td>{{ listSolicitItem.valor_unitario }}</td>
                  <td>{{ listSolicitItem.valor_totalProduto }}</td>
                  <td>
                    <SubmitFormButton
                      btnText="Deletar"
                      class="underline text-red-500 hover:text-red-600"
                      @click="deleteItemSolitacao(listSolicitItem.id)"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
            <div class="bg-green-500 w-full h-1"></div>
            <div class="flex items-center justify-center p-2">
              <v-pagination
                v-model="pageItems"
                :pages="pageCountItems"
                :range-size="1"
                active-color="#337aff"
                @update:modelValue="getSolicitItemList"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { ref } from "vue";
import NavbarComponent from "@/components/Navbar.vue";
import SidebarComponent from "@/components/Sidebar.vue";
import AdmMenuComponent from "@/components/parcial/adm/AdmMenu.vue";
import RouterLinkButton from "@/components/global/RouterLinkButton.vue";
import TextInput from "@/components/global/TextInput.vue";
import TextInputDate from "@/components/global/TextInputDate.vue";
import TextInputMoeda from "@/components/global/TextInputMoeda.vue";
import LoadingButton from "@/components/global/LoadingButton.vue";
import SubmitFormButton from "@/components/global/SubmitFormButton.vue";
import { useRoute, useRouter } from "vue-router";
import { useUserStore } from "@/store/user-store";
import { useSupplierStore } from "@/store/solicitationsupplier-store";

export default {
  components: {
    NavbarComponent,
    SidebarComponent,
    AdmMenuComponent,
    RouterLinkButton,
    LoadingButton,
    TextInput,
    SubmitFormButton,
    TextInputDate,
    TextInputMoeda,
  },
  name: "regular-modal",
  data() {
    const userStore = useUserStore();
    const SupplierStore = useSupplierStore();
    const route = useRoute();
    const router = useRouter();

    const url = process.env.VUE_APP_API_URL;
    let hover = ref(false);
    let showModal = ref(false);
    let showModalSupplier = ref(false);
    let showModalProduct = ref(false);
    let showModalProductCB = ref(false);

    let searchCompany = ref(null);
    let listCompanies = ref(null);
    let pageCompanies = ref(1);
    let pageCountCompanies = ref(null);

    let errors = ref([]);

    return {
      date: new Date().getFullYear(),
      useradministrador: userStore.administrador,
      userid_company: userStore.id_empresa,
      id_responsavel: userStore.id,
      name_responsavel: userStore.full_name,
      SupplierStore: SupplierStore,
      route: route,
      router: router,
      url_api: url,
      hover: hover,
      showModal: showModal,
      showModalSupplier: showModalSupplier,
      showModalProduct: showModalProduct,
      showModalProductCB: showModalProductCB,
      searchCompany: searchCompany,
      listCompanies: listCompanies,
      pageCompanies: pageCompanies,
      pageCountCompanies: pageCountCompanies,
      listStocks: ref(null),
      searchStock: ref(null),
      searchStockCB: ref(null),
      pageStocks: ref(1),
      pageCountStocks: ref(null),
      searchSupplier: ref(null),
      listSuppliers: ref(null),
      pageSuppliers: ref(1),
      pageCountSuppliers: ref(null),
      listSolicitItems: ref(null),
      pageItems: ref(null),
      pageCountItems: ref(null),
      errors: errors,
      error: errors,
      id_empresa: ref(null),
      isLoading: ref(false),
      id_solicitacao: ref(null),
      id_cliente: ref(null),
      razaoSocial: ref(null),
      id_fornecedor: ref(null),
      nome_fornecedor: ref(null),
      dt_emissao: ref(null),
      dt_retirada: ref(null),
      dt_entrega: ref(null),
      valorTotal: ref(null),
      vlrtotal_item: ref(null),
      quantidade: ref(null),
      vlrunitario: ref(null),
    };
  },
  methods: {
    async getSolicitationsupplier() {
      // Make a GET request to the API
      const response = await this.SupplierStore.fetchByIdSolicitationsupplier(
        this.route.params.id
      );
      this.id_solicitacao = this.SupplierStore.id || null;
      this.id_cliente = this.SupplierStore.id_cliente || null;
      this.razaoSocial = this.SupplierStore.razaoSocial || null;
      this.id_fornecedor = this.SupplierStore.id_fornecedor || null;
      this.nome_fornecedor = this.SupplierStore.nome_fornecedor || null;
      this.dt_emissao = this.SupplierStore.dt_emissao || null;
      this.dt_retirada = this.SupplierStore.dt_retirada || null;
      this.dt_entrega = this.SupplierStore.dt_entrega || null;
      this.vlrtotal = this.SupplierStore.valorTotalPedido || null;
      this.$snotify.info("Aguarde atualizando ... !");
    },
    getCompanyList() {
      // Make a GET request to the API
      axios
        .get(
          this.url_api +
            "/api/companysearch/" +
            this.searchCompany +
            "?page=" +
            this.pageCompanies
        )
        .then((response) => {
          // Handle the successful response
          // console.log(response.data);
          this.pageCountCompanies = response.data.page_count1;
          this.listCompanies = response.data.paginate1.data;
        })
        .catch((error) => {
          // Handle any errors
          console.error("Error fetching data:", error);
          this.$snotify.error("Verifique novamente !");
        });
    },
    getSupplierList() {
      axios
        .get(
          this.url_api +
            "/api/suppliersearch/" +
            this.searchSupplier +
            "?page=" +
            this.pageSuppliers
        )
        .then((response) => {
          // Handle the successful response
          // console.log(response.data);
          this.pageCountSuppliers = response.data.page_count1;
          this.listSuppliers = response.data.paginate1.data;
        })
        .catch((error) => {
          // Handle any errors
          console.error("Error fetching data:", error);
          this.$snotify.error("Verifique novamente !");
        });
    },
    getStockListProd() {
      axios
        .get(
          this.url_api +
            "/api/stocksearch/" +
            this.id_cliente +
            "/" +
            this.searchStock +
            "?page=" +
            this.pageStocks
        )
        .then((response) => {
          // Handle the successful response
          // console.log(response.data);
          this.pageCountStocks = response.data.page_count;
          this.listStocks = response.data.paginate.data;
        })
        .catch((error) => {
          // Handle any errors
          console.error("Error fetching data:", error);
          this.$snotify.error("Verifique novamente !");
        });
    },
    getStockListProdCB() {
      axios
        .get(
          this.url_api +
            "/api/stocksearchCB/" +
            this.id_cliente +
            "/" +
            this.searchStockCB +
            "?page=" +
            this.pageStocks
        )
        .then((response) => {
          // Handle the successful response
          // console.log(response.data);
          this.pageCountStocks = response.data.page_count;
          this.listStocks = response.data.paginate.data;
        })
        .catch((error) => {
          // Handle any errors
          console.error("Error fetching data:", error);
          this.$snotify.error("Verifique novamente !");
        });
    },
    getSolicitItemList() {
      axios
        .get(
          this.url_api +
            "/api/salesoitementrylist/" +
            this.route.params.id +
            "?page=" +
            this.pageItems
        )

        // console.log(resItem)

        .then((response) => {
          // Handle the successful response
          // console.log(response.data);
          this.pageCountItems = response.data.page_countItem;
          this.listSolicitItems = response.data.paginateItem.data;
          this.vlrtotal = response.data.valorTotalPedido;
        })
        .catch((error) => {
          // Handle any errors
          console.error("Error fetching data:", error);
          this.$snotify.error("Verifique novamente !");
        });
    },

    toggleModal() {
      this.showModal = !this.showModal;
    },
    toggleModalSupplier() {
      this.showModalSupplier = !this.showModalSupplier;
    },
    toggleModalProduct() {
      this.showModalProduct = !this.showModalProduct;
    },
    toggleModalProductCB() {
      this.showModalProductCB = !this.showModalProductCB;
    },
    selectCompany(event) {
      this.id_cliente = event.id;
      this.razaoSocial = event.razaoSocial;

      if (this.userid_company !== this.id_cliente) {
        this.$snotify.error("Empresa não pertence a : " + this.razaoSocial);
        this.id_cliente = null;
        this.razaoSocial = null;
      } else {
        this.$snotify.warning("Escolheu a : " + this.razaoSocial);
      }
    },
    selectSupplier(event) {
      this.id_fornecedor = event.id;
      this.nome_fornecedor = event.razaoSocial;
      this.$snotify.warning("Escolheu a : " + this.nome_fornecedor);
    },
    selectProduct(event) {
      this.id_produto = event.id;
      this.nome_produto = event.nome_produto;
      this.codigo_barra = event.codigo_barra;
      this.descricao = event.nome_produto;
      this.unidade = event.unidade;
      this.vlrunitario = event.preco_venda;

      this.$snotify.warning("Escolheu a : " + this.nome_produto);
    },
    onSubmit() {
      this.isLoading = true;
      setTimeout(() => (this.isLoading = false), 1000);
    },
    multiplicarprod() {
      // alert(`Selecionou !`)
      this.vlrtotal_item = this.quantidade * this.vlrunitario;
    },
    registerItemSolicitacao() {
      if (this.id_solicitacao == null) {
        this.$snotify.warning("Register solicitation (Registrar solicitação)");
      }

      if (this.dt_emissao == null) {
        this.$snotify.warning("Enter request date(Digitar data de emissão)");
      }

      if (this.codigo_barra == null) {
        this.$snotify.warning("Enter request product(Incluir produto)");
      }

      this.error = [];
      let data = new FormData();
      data.append("id_solicitacao", this.id_solicitacao || "");
      data.append("id_produto", this.id_produto || "");
      data.append("descricao", this.descricao || "");
      data.append("dt_emissao", this.dt_emissao || "");
      data.append("unidade", this.unidade || "");
      data.append("vlrunitario", this.vlrunitario || "");
      data.append("quantidade", this.quantidade || "");
      data.append("vlrtotal_item", this.vlrtotal_item || "");

      axios
        .post(this.url_api + "/api/salesoitementry", {
          id_pedido_item: this.id_solicitacao,
          id_prod_item: this.id_produto,
          codigo_barra: this.codigo_barra,
          nome_produto: this.descricao,
          dt_emissao: this.dt_emissao,
          unidade: this.unidade,
          valor_unitario: this.vlrunitario,
          quantidade: this.quantidade,
          valor_totalProduto: this.vlrtotal_item,
        })

        .then(() => {
          // Handle the successful response
          // console.log(response.data);
          this.$snotify.success(
            "Product successfully included in the request(Produto incluido com sucesso na solicitação)"
          );
          this.getSolicitItemList();
        })
        .catch((error) => {
          // Handle any errors
          console.error("Error fetching data:", error);
          this.$snotify.error("Verifique, falta informação !");
        });
    },

    update() {
      // console.log('Dentro update')

      this.error = [];
      let data = new FormData();
      data.append("id_responsavel", this.id_responsavel || "");
      data.append("id_cliente", this.id_cliente || "");
      data.append("id_fornecedor", this.id_fornecedor || "");
      data.append("dt_emissao", this.dt_emissao || "");
      data.append("dt_retirada", this.dt_retirada || "");
      data.append("dt_entrega", this.dt_entrega || "");
      data.append("vlrtotal", this.vlrtotal || "");
      // console.log(data)

      axios
        .put(this.url_api + "/api/salesorderentry/" + this.route.params.id, {
          id_responsavel: this.id_responsavel,
          id_cliente: this.id_cliente,
          id_fornecedor: this.id_fornecedor,
          dt_emissao: this.dt_emissao,
          dt_retirada: this.dt_retirada,
          dt_entrega: this.dt_entrega,
          valorTotalPedido: this.vlrtotal,
        })
        .then(() => {
          // console.log(teste)
          this.$snotify.success("Salvo com sucesso !");
        })
        .catch((error) => {
          // Handle any errors
          // this.errors = error.data.error;
          console.error("Error salesorderentry:", this.error);
          this.$snotify.error("Verificar Empresa, Fornecedor ou as datas !");
          return error;
        });
    },
    deleteItemSolitacao(event) {
      // console.log(event)

      axios
        .delete(this.url_api + "/api/salesoitementry/" + event)
        .then(() => {
          // console.log(teste)
          this.getSolicitItemList();
          this.$snotify.success("Salvo com sucesso !");
        })
        .catch((error) => {
          // Handle any errors
          // this.errors = error.data.error;
          console.error("Error deleteItemSolitacao:", this.error);
          this.$snotify.error("Verificar Item !");
          return error;
        });
    },
    onPageChange() {
      this.getSolicitationsupplier();
      this.getSolicitItemList();
      this.getCompanyList();
      this.getSupplierList();
      this.getStockListProd();
      this.getStockListProdCB();
      this.update();
    },
  },
  mounted() {
    this.getSolicitationsupplier();
    this.getSolicitItemList();
  },
};
</script>

<!-- <template>
  <div>
    <AdmTopNavigation />
  </div>
  <div class="
        container 
        mx-auto 
        my-12
        h-auto
        flex 
        flex-wrap 
        justify-between 
        items-start 
        text-sm 
        p-8 
        pt-8 
        pb-4 
        box-border 
        border-4 
        border-gray-400 
        bg-gray-200">

    <p class="text-2xl text-center text-black font-bold">Solicitação : Alterar Saída </p>
    <RouterLinkButton btnText="Retornar" color="sky" tipo="fas" icone="arrow-left" url="/solicitacao" />
    <div class="mx-auto py-4">
      <div class="flex flex-wrap font-bold text-gray-100">
        <div class="w-full mt-4">
          <div class="text-gray-900 text-xl">
            Número {{ id_solicitacao }}
          </div>
          <RouterLinkButton btnText="Imprimir" color="sky" tipo="fas" icone="arrow-left" url="" />
        </div>
        <div class="bg-green-500 w-full h-1"></div>
        <div class="flex mt-4 mb-8">
          <div class="w-full md:w-1/2 px-3">
            <TextInput label="Pesquisar empresa" placeholder="Pesquisar..." v-model:input="searchCompany"
              inputType="text" />
          </div>
          <div class="w-full md:w-1/2 px-3">
            <form @submit.prevent="onSubmit" @click="getCompanyList">
              <LoadingButton :isLoading="isLoading" class="
              block w-full
              bg-green-500
              text-white
              rounded-sm
              py-3 text-sm
              tracking-wide
              transform 
              motion-safe:hover:-translate-y-1 
              text-blue-700 
              motion-safe:hover:scale-110 
              transition 
              ease-in-out">
                Pesquisar empresa
              </LoadingButton>
            </form>
          </div>
          <div class="mx-auto py-4">
            <table class="table-auto text-xs">
              <thead>
                <tr class="text-gray-600 bg-gray-300">
                  <th class="border px-4 py-2">Id</th>
                  <th class="border px-4 py-2">Solicitante</th>
                  <th class="border px-4 py-2"></th>
                  <th class="border px-4 py-2">Ação</th>
                </tr>
              </thead>
              <tbody>
                <tr class="text-gray-500 bg-gray-100" v-for="listCompany in listCompanies" :key="listCompany">
                  <td>{{ listCompany.id }}</td>
                  <td>{{ listCompany.razaoSocial }}</td>
                  <td>{{ listCompany.nomeFantasia }}</td>
                  <td>
                    <div class="text-lg">
                      <SubmitFormButton btnText="Escolha" @click="selectCompany(listCompany)" />
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <div class="flex items-center justify-center p-2 ">
              <v-pagination v-model="pageCompanies" :pages="pageCountCompanies" :range-size="1" active-color="#337aff"
                @update:modelValue="listCompanies" />
            </div>
          </div>
        </div>
        <div class="bg-green-500 w-full h-1"></div>
        <div class="flex mt-4 mb-8">
          <div class="w-full text-gray-700 md:w-1/2 px-3">
            <TextInput label="Id" placeholder="" v-model:input="id_razaoSocial" inputType="hidden" readonly />
            {{ id_razaoSocial }}
          </div>
          <div class="w-full text-gray-700 md:w-1/2 px-3">
            <TextInput label="Solicitante" placeholder="Solicitante" v-model:input="razaoSocial" inputType="hidden" />
            {{ razaoSocial }}
          </div>
          <div class="w-full text-gray-700 md:w-1/2 px-3">
            <TextInputDate label="Data emissão" placeholder="Digitar emissão !" v-model:input="dt_emissao"
              inputType="text" />
          </div>
          <div class="w-full text-gray-700 md:w-1/2 px-3">
            <TextInputDate label="Data retirada" placeholder="Digitar retirada !" v-model:input="dt_retirada"
              inputType="text" />
          </div>
          <div class="w-full text-gray-700 md:w-1/2 px-3">
            <TextInputDate label="Data entrega" placeholder="Digitar entrega !" v-model:input="dt_entrega"
              inputType="text" />
          </div>
          <div class="w-full text-gray-700 md:w-1/2 px-3">
            <TextInput label="Vlr Solicitação" placeholder="Valor total" v-model:input="vlrtotal" inputType="hidden" />
            {{ vlrtotal }}
          </div>
          <div class="w-full md:w-1/2 px-3">
            <form @submit.prevent="onSubmit" @click="alterSolicitacao">
              <LoadingButton :isLoading="isLoading" class="
              block w-full
              bg-green-500
              text-white
              rounded-sm
              py-3 text-sm
              tracking-wide
              transform 
              motion-safe:hover:-translate-y-1 
              text-blue-700 
              motion-safe:hover:scale-110 
              transition 
              ease-in-out">
                Alterar/Finalizar
              </LoadingButton>
            </form>
          </div>
        </div>
        <div class="bg-green-500 w-full h-1"></div>
        <div class="mx-auto py-4">
          <div class="w-full ok px-6">
            <TextInput label="Pesquisar Nome Produto" placeholder="Pesquisar..." v-model:input="searchproduct"
              inputType="text" />
          </div>
        </div>
        <div class="flex flex-wrap mt-8 mb-6">
          <form @submit.prevent="onSubmit" @click="getStockListProd">
            <LoadingButton :isLoading="isLoading" class="
              block w-full
              bg-green-500
              text-white
              rounded-sm
              py-3 text-sm
              tracking-wide
              transform 
              motion-safe:hover:-translate-y-1 
              text-blue-700 
              motion-safe:hover:scale-110 
              transition 
              ease-in-out">
              Pesquisar produto
            </LoadingButton>
          </form>
        </div>
        <div class="mx-auto py-4">
          <table class="table-auto text-xs">
            <thead>
              <tr class="text-gray-600 bg-gray-300">
                <th class="border px-4 py-2">Código barra</th>
                <th class="border px-4 py-2">Produto</th>
                <th class="border px-4 py-2">Estoque</th>
                <th class="border px-4 py-2">Preço venda</th>
                <th class="border px-4 py-2">Action(Ação)</th>
              </tr>
            </thead>
            <tbody>
              <tr class="text-gray-500 bg-gray-100" v-for="listStock in listStocks" :key="listStock">
                <td>{{ listStock.codigo_barra }}</td>
                <td>{{ listStock.nome_produto }}</td>
                <td>{{ listStock.estoque }}</td>
                <td>{{ listStock.preco_venda }}</td>
                <td>
                  <div class="text-lg">
                    <SubmitFormButton btnText="Escolha" @click="selectprod(listStock)" />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <div class="flex items-center justify-center p-2 ">
            <v-pagination v-model="page" :pages="pageCountProducts" :range-size="1" active-color="#337aff"
              @update:modelValue="listStocks" />
          </div>
        </div>
        <div class="flex mt-2 mb-2">
          <div class="w-full text-gray-700 md:w-1/6 px-3">
            <TextInput label="" placeholder="" v-model:input="codigo" inputType="hidden" />
            <TextInput label="Código barra" placeholder="Código barra" v-model:input="codigo_barra" inputType="hidden" />
            {{ codigo_barra }}
          </div>
          <div class="w-full text-gray-700 md:w-1/2 px-8">
            <TextInput label="Descrição" placeholder="Descricao" v-model:input="descricao" inputType="hidden" />
            {{ descricao }}
          </div>
          <div class="w-full text-gray-700 md:w-1/12 px-3">
            <TextInput label="Unidade" placeholder="Unidade" v-model:input="unidade" inputType="hidden" />
            {{ unidade }}
          </div>
          <div class="w-full md:w-1/12 px-3">
            <TextInput label="Quantidade" placeholder="Quantidade" v-model:input="quantidade" inputType="text" />
            <SubmitFormButton btnText="Qtd + Vlr Unitátio" @click="multiplicarprod()" />
          </div>
          <div class="w-full md:w-auto px-3">
            <TextInput label="Valor unitário" placeholder="Valor unitário" v-model:input="vlrunitario" inputType="text" />
          </div>
          <div class="w-full text-gray-700 md:w-auto px-3">
            <TextInput label="Valor total" placeholder="Valor total" v-model:input="vlrtotal_item" inputType="hidden" />
            {{ vlrtotal_item }}
          </div>
          <div class="flex flex-wrap mt-8 mb-6">
            <div class="w-full md:w-auto px-6">
              <form @submit.prevent="onSubmit" @click="registerItemSolicitacao">
                <LoadingButton :isLoading="isLoading" class="
                  block w-full
                  bg-green-500
                  text-white
                  rounded-sm
                  py-3 text-sm
                  tracking-wide
                  transform 
                  motion-safe:hover:-translate-y-1 
                  text-blue-700 
                  motion-safe:hover:scale-110 
                  transition 
                  ease-in-out">
                  Include(Incluir)
                </LoadingButton>
              </form>
            </div>

          </div>
        </div>
        <div class="bg-green-500 w-full h-1"></div>
        <div class="text-gray-600 text-xl">
          Itens da solicitação
        </div>
        <div class="bg-green-500 w-full h-1"></div>
        <table class="table-auto text-xl">
          <thead>
            <tr class="text-gray-600 bg-gray-300">
              <th class="border px-4 py-2">Solicitação</th>
              <th class="border px-4 py-2">Codigo barra</th>
              <th class="border px-4 py-2">Descrição</th>
              <th class="border px-4 py-2">Classe(Un)</th>
              <th class="border px-4 py-2">Quantidade</th>
              <th class="border px-4 py-2">Valor unitário</th>
              <th class="border px-4 py-2">Valor total</th>
              <th class="border px-4 py-2">Ação</th>
            </tr>
          </thead>
          <tbody>
            <tr class="text-gray-500 bg-gray-100" v-for="listSolicitItem in listSolicitItems" :key="listSolicitItem">
              <td>{{ listSolicitItem.id_pedido_item }}</td>
              <td>{{ listSolicitItem.codigo_barra }}</td>
              <td>{{ listSolicitItem.nome_produto }}</td>
              <td>{{ listSolicitItem.unidade }}</td>
              <td>{{ listSolicitItem.quantidade }}</td>
              <td>{{ listSolicitItem.valor_unitario }}</td>
              <td>{{ listSolicitItem.valor_totalProduto }}</td>
              <td>
                <SubmitFormButton btnText="Deletar" class="underline text-red-500 hover:text-red-600"
                  @click="deleteItemSolitacao(listSolicitItem)" />
              </td>
            </tr>
          </tbody>
        </table>
        <div class="bg-green-500 w-full h-1"></div>
        <div class="flex items-center justify-center p-2 ">
          <v-pagination v-model="pageItems" :pages="pageCountItems" :range-size="1" active-color="#337aff"
            @update:modelValue="getSolicitItemList" />
        </div>

      </div>
    </div>
  </div>
</template>
<script setup>
import axios from 'axios';
import { onMounted, ref } from 'vue';
import AdmTopNavigation from "@/components/parcial/adm/AdmTopNavigation.vue";
import TextInput from "@/components/global/TextInput.vue";
import TextInputDate from "@/components/global/TextInputDate.vue";
import SubmitFormButton from '@/components/global/SubmitFormButton.vue';
import RouterLinkButton from "@/components/global/RouterLinkButton.vue";
import { useRoute, useRouter } from 'vue-router';
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
import LoadingButton from '@/components/global/LoadingButton.vue';
import { useSolicitationStore } from '@/store/solicitation-store';

const route = useRoute()
const router = useRouter()
const isLoading = ref(false)

function onSubmit() {
  isLoading.value = true;
  setTimeout(() => isLoading.value = false, 1000)
}

let errors = ref([]);
let open = ref(false)
let listStocks = ref(null)
let pageStocks = ref(1)
let pageCountStocks = ref(null)
let searchproduct = ref(null)
let searchCompany = ref(null)
let listCompanies = ref(null)
let pageCompanies = ref(1)
let pageCountCompanies = ref(null)
let listSolicitItems = ref(null)
let pageItems = ref(1)
let pageCountItems = ref(null)


let id_solicitacao = ref(null);
let codigo = ref(null);
let codigo_barra = ref(null);
let id_razaoSocial = ref(null);
let razaoSocial = ref(null);
let descricao = ref(null);
let unidade = ref(null);
let quantidade = ref(0);
let vlrunitario = ref(null);
let vlrtotal = ref(null);
let dt_emissao = ref(null);
let dt_retirada = ref(null);
let dt_entrega = ref(null);
let situacao = ref(null);

let vlrtotal_item = ref(null);
let id_itemsolicitacao = ref(null)

const url = process.env.VUE_APP_API_URL;
let url_api = url

const solicitationsStore = useSolicitationStore()

onMounted(async () => {

  await solicitationsStore.fetchByIdSolicitation(route.params.id)

  id_solicitacao.value = solicitationsStore.id_solicitacao || null
  id_razaoSocial.value = solicitationsStore.id_razaoSocial || null
  razaoSocial.value = solicitationsStore.razaoSocial || null
  vlrtotal.value = solicitationsStore.vlrtotal || null
  dt_emissao.value = solicitationsStore.dt_emissao || null
  dt_retirada.value = solicitationsStore.dt_retirada || null
  dt_entrega.value = solicitationsStore.dt_entrega || null
  situacao.value = solicitationsStore.situacao || null

  await getSolicitItemList()


})

const getSolicitItemList = async () => {
  try {

    let resItem = await axios.get(url_api + '/api/salesoitemlist/' + route.params.id + '?page=' + pageItems.value)

    // console.log(resItem)

    pageCountItems.value = resItem.data.page_countItem
    listSolicitItems.value = resItem.data.paginateItem.data
    vlrtotal.value = resItem.data.valorTotalPedido

  } catch (err) {
    console.log(err)
  }

}

const alterSolicitacao = async () => {

  if (dt_emissao.value == null) {
    alert('Enter request date(Digitar data de emissão)')
  }

  try {

    let res = await axios.put(url_api + '/api/salesorder/' + route.params.id, {
      id_cliente: id_razaoSocial.value,
      razaoSocial: razaoSocial.value,
      dt_emissao: dt_emissao.value,
      dt_retirada: dt_retirada.value,
      dt_entrega: dt_entrega.value,
      valorTotalPedido: vlrtotal.value

    })

    // console.log(res)

    // id_solicitacao.value = res.data.salesorder.id
    // dt_emissao.value = res.data.salesorder.dt_emissao

  } catch (err) {

    console.log(err)
    errors.value = err.response.errors

  }

}

const registerItemSolicitacao = async () => {

  if (id_solicitacao.value == null) {
    alert('Register solicitation (Registrar solicitação)')
  }

  if (dt_emissao.value == null) {
    alert('Enter request date(Digitar data de emissão)')
  }

  if (codigo_barra.value == null) {
    alert('Enter request product(Incluir produto)')
  }

  errors.value = []

  try {

    let res = await axios.post(url_api + '/api/salesoitem', {

      id_pedido_item: id_solicitacao.value,
      id_prod_item: codigo.value,
      codigo_barra: codigo_barra.value,
      nome_produto: descricao.value,
      dt_emissao: dt_emissao.value,
      unidade: unidade.value,
      valor_unitario: vlrunitario.value,
      quantidade: quantidade.value,
      valor_totalProduto: vlrtotal_item.value

    })

    // console.log(res)

    await getSolicitItemList()
    alert('Product successfully included in the request(Produto incluido com sucesso na solicitação)')

  } catch (err) {

    console.log(err)
    errors.value = err.response.data.errors

  }

}

const getStockListProd = async () => {

  errors.value = []

  try {

    let res = await axios.get(url_api + '/api/salesordersearch/' + searchproduct.value + '?page=' + pageStocks.value)

    pageCountStocks.value = res.data.page_count
    listStocks.value = res.data.paginate.data

    // console.log(res)

  } catch (err) {

    console.log(err)
    errors.value = err.response.data.errors

  }

}

function selectprod(event) {

  //console.log(event)
  // alert(`Selecionou ! : ` + event.id + ' - ' + event.nome_produto)
  codigo.value = event.id
  codigo_barra.value = event.codigo_barra
  descricao.value = event.nome_produto
  unidade.value = event.unidade
  vlrunitario.value = event.preco_venda
}

function multiplicarprod() {

  // alert(`Selecionou !`)
  vlrtotal_item.value = quantidade.value * vlrunitario.value

}

const getCompanyList = async () => {
  try {

    let res2 = await axios.get(url_api + '/api/companysearch/' + searchCompany.value + '?page=' + pageCompanies.value)

    console.log(res2)
    pageCountCompanies.value = res2.data.page_count1
    listCompanies.value = res2.data.paginate1.data


  } catch (err) {
    console.log(err)
  }
}

function selectCompany(event) {

  // console.log(event)
  // alert(`Selecionou ! : ` + event.id + ' - ' + event.razaoSocial)
  id_razaoSocial.value = event.id
  razaoSocial.value = event.razaoSocial

}

const deleteItemSolitacao = async (event) => {

  try {

    id_itemsolicitacao = event.id

    let resDelete = await axios.delete(url_api + '/api/salesoitem/' + id_itemsolicitacao)

    // console.log(resDelete)

    alert(`Selecionou item para delete! : ` + resDelete.data)

    await getSolicitItemList()

  } catch (err) {
    console.log(err)
  }

}

</script> -->
