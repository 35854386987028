<template>
  <div id="app">
    <Chat />
  </div>
</template>

<script>
import Chat from "@/components/chat/MessageComponent.vue";

export default {
  components: {
    Chat,
  },
};
</script>
