<template>
  <div>
    <sidebar-component></sidebar-component>
    <div class="relative md:ml-64 bg-blueGray-100">
      <navbar-component></navbar-component>
      <!-- Header -->
      <div class="relative bg-blue-600 md:pt-32 pb-32 pt-12">
        <div class="rounded-t mb-0 px-4 py-3 border-0">
          <div
            class="container mx-auto my-12 h-auto flex flex-wrap justify-between items-start text-sm p-8 pt-8 pb-4 box-border border-4 border-gray-400 bg-gray-100"
          >
            <a class="text-2xl text-center text-black font-bold"
              >Solicitação : Cadastro de Saída(Venda) {{ id_solicitacao }}
            </a>
            <RouterLinkButton
              btnText=""
              color="sky"
              tipo="fas"
              icone="arrow-left"
              url="/solicitacao"
            />
            <div class="bg-green-500 w-full h-1"></div>
            <a class="text-sky-400 font-bold"
              >Empresa : {{ id_cliente }}-{{ razaoSocial }}</a
            >
            <div>
              <button
                class="bg-gray-400 text-white active:bg-pink-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="button"
                v-on:click="toggleModal()"
              >
                Escolha a Empresa que pertence a Saída(Venda) !
              </button>
              <div
                v-if="showModal"
                class="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex"
              >
                <div class="relative w-auto my-6 mx-auto max-w-3xl">
                  <!--content-->
                  <div
                    class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none"
                  >
                    <!--header-->
                    <div
                      class="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t"
                    >
                      <h3 class="text-3xl font-semibold">Empresa</h3>
                      <button
                        class="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                        v-on:click="toggleModal()"
                      >
                        <span
                          class="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none"
                        >
                          ×
                        </span>
                      </button>
                    </div>
                    <!--body-->
                    <div class="relative p-6 flex-auto">
                      <div class="flex mt-4 mb-8">
                        <div class="w-full md:w-1/2 px-3">
                          <TextInput
                            label="Digitar o nome da Empresa"
                            placeholder="Pesquisar..."
                            v-model:input="searchCompany"
                            inputType="text"
                          />
                        </div>
                        <div class="w-full md:w-1/2 px-3">
                          <form
                            @submit.prevent="onSubmit"
                            @click="getCompanyList"
                          >
                            <LoadingButton
                              :isLoading="isLoading"
                              class="block w-full bg-green-500 text-white rounded-sm py-3 text-sm tracking-wide transform motion-safe:hover:-translate-y-1 text-blue-700 motion-safe:hover:scale-110 transition ease-in-out"
                            >
                              Pesquisar
                            </LoadingButton>
                          </form>
                        </div>
                        <div class="mx-auto py-4">
                          <table class="table-auto text-xs">
                            <thead>
                              <tr class="text-gray-600 bg-gray-300">
                                <th class="border px-4 py-2">Id</th>
                                <th class="border px-4 py-2">Empresa</th>
                                <th class="border px-4 py-2"></th>
                                <th class="border px-4 py-2">Ação</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                class="text-gray-500 bg-gray-100"
                                v-for="listCompany in listCompanies"
                                :key="listCompany"
                              >
                                <td>{{ listCompany.id }}</td>
                                <td>{{ listCompany.razaoSocial }}</td>
                                <td>{{ listCompany.nomeFantasia }}</td>
                                <td>
                                  <div
                                    class="block w-full bg-green-500 text-white rounded-sm py-3 text-sm tracking-wide transform motion-safe:hover:-translate-y-1 text-blue-700 motion-safe:hover:scale-110 transition ease-in-out"
                                  >
                                    <SubmitFormButton
                                      btnText="Escolha"
                                      @click="selectCompany(listCompany)"
                                    />
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          <div class="flex items-center justify-center p-2">
                            <v-pagination
                              v-model="pageCompanies"
                              :pages="pageCountCompanies"
                              :range-size="1"
                              active-color="#337aff"
                              @update:modelValue="listCompanies"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <vue-snotify />
                    <!--footer-->
                    <div
                      class="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b"
                    >
                      <button
                        class="text-red-500 bg-transparent border border-solid border-red-500 hover:bg-red-500 hover:text-white active:bg-red-600 font-bold uppercase text-sm px-6 py-3 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                        type="button"
                        v-on:click="toggleModal()"
                      >
                        Sair
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div
                v-if="showModal"
                class="opacity-25 fixed inset-0 z-40 bg-black"
              ></div>
            </div>
            <div class="bg-green-500 w-full h-1"></div>
            <a class="text-sky-400 font-bold"
              >Cliente : {{ id_clienteVenda }}-{{ clienteVenda }}</a
            >
            <div>
              <button
                class="bg-gray-400 text-white active:bg-pink-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="button"
                v-on:click="toggleModalCliente()"
              >
                Escolha a cliente que pertence a Saída(Venda) !
              </button>
              <div
                v-if="showModalCliente"
                class="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex"
              >
                <div class="relative w-auto my-6 mx-auto max-w-3xl">
                  <!--content-->
                  <div
                    class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none"
                  >
                    <!--header-->
                    <div
                      class="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t"
                    >
                      <h3 class="text-3xl font-semibold">Cliente</h3>
                      <button
                        class="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                        v-on:click="toggleModalCliente()"
                      >
                        <span
                          class="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none"
                        >
                          ×
                        </span>
                      </button>
                    </div>
                    <!--body-->
                    <div class="relative p-6 flex-auto">
                      <div class="flex mt-4 mb-8">
                        <div class="w-full md:w-1/2 px-3">
                          <TextInput
                            label="Digitar o nome da Cliente"
                            placeholder="Pesquisar..."
                            v-model:input="searchCliente"
                            inputType="text"
                          />
                        </div>
                        <div class="w-full md:w-1/2 px-3">
                          <form
                            @submit.prevent="onSubmit"
                            @click="getClienteList"
                          >
                            <LoadingButton
                              :isLoading="isLoading"
                              class="block w-full bg-green-500 text-white rounded-sm py-3 text-sm tracking-wide transform motion-safe:hover:-translate-y-1 text-blue-700 motion-safe:hover:scale-110 transition ease-in-out"
                            >
                              Pesquisar
                            </LoadingButton>
                          </form>
                        </div>
                        <div class="mx-auto py-4">
                          <table class="table-auto text-xs">
                            <thead>
                              <tr class="text-gray-600 bg-gray-300">
                                <th class="border px-4 py-2">Id</th>
                                <th class="border px-4 py-2">Cliente</th>
                                <th class="border px-4 py-2">Ação</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                class="text-gray-500 bg-gray-100"
                                v-for="listCliente in listClientes"
                                :key="listCliente"
                              >
                                <td>{{ listCliente.id }}</td>
                                <td>{{ listCliente.full_name }}</td>
                                <td>
                                  <div
                                    class="block w-full bg-green-500 text-white rounded-sm py-3 text-sm tracking-wide transform motion-safe:hover:-translate-y-1 text-blue-700 motion-safe:hover:scale-110 transition ease-in-out"
                                  >
                                    <SubmitFormButton
                                      btnText="Escolha"
                                      @click="selectCliente(listCliente)"
                                    />
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          <div class="flex items-center justify-center p-2">
                            <v-pagination
                              v-model="pageClientes"
                              :pages="pageCountClientes"
                              :range-size="1"
                              active-color="#337aff"
                              @update:modelValue="listClientes"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <vue-snotify />
                    <!--footer-->
                    <div
                      class="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b"
                    >
                      <button
                        class="text-red-500 bg-transparent border border-solid border-red-500 hover:bg-red-500 hover:text-white active:bg-red-600 font-bold uppercase text-sm px-6 py-3 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                        type="button"
                        v-on:click="toggleModalCliente()"
                      >
                        Sair
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div
                v-if="showModalCliente"
                class="opacity-25 fixed inset-0 z-40 bg-black"
              ></div>
            </div>
            <div class="bg-green-500 w-full h-1"></div>
            <div class="w-full md:w-full px-3">
              <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
                <div
                  class="w-full border px-4 py-2 rounded focus:border-blue-500 focus:shadow-outline outline-none"
                >
                  <div class="w-full text-gray-900 md:w-full px-6">
                    <TextInputDate
                      label="Data emissão"
                      placeholder="Digitar emissão !"
                      v-model:input="dt_emissao"
                      inputType="text"
                    />
                  </div>
                </div>
                <div class="w-full text-gray-900 md:w-full px-6">
                  <TextInputMoeda
                    label="Vlr Solicitação"
                    placeholder="Valor total"
                    v-model:input="vlrtotal_solicitacao"
                    inputType="text"
                  />
                </div>
              </div>
            </div>

            <div class="bg-green-300 w-full h-1"></div>
            <div v-if="id_solicitacao !== null">
              <button
                class="bg-gray-500 text-white active:bg-pink-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="button"
                v-on:click="toggleModalProduct()"
              >
                Escolha o produto que pertence a entrada por descrição!
              </button>
              <div
                v-if="showModalProduct"
                class="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex"
              >
                <div class="relative w-auto my-6 mx-auto max-w-3xl">
                  <!--content-->
                  <div
                    class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none"
                  >
                    <!--header-->
                    <div
                      class="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t"
                    >
                      <h3 class="text-3xl font-semibold">Produto</h3>
                      <button
                        class="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                        v-on:click="toggleModalProduct()"
                      >
                        <span
                          class="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none"
                        >
                          ×
                        </span>
                      </button>
                    </div>
                    <!--body-->
                    <div class="relative p-6 flex-auto">
                      <div class="flex mt-4 mb-8">
                        <div class="w-full md:w-1/2 px-3">
                          <TextInput
                            label="Digitar o nome do produto"
                            placeholder="Pesquisar..."
                            v-model:input="searchStock"
                            inputType="text"
                          />
                        </div>
                        <div class="w-full md:w-1/2 px-3">
                          <form
                            @submit.prevent="onSubmit"
                            @click="getStockListProd"
                          >
                            <LoadingButton
                              :isLoading="isLoading"
                              class="block w-full bg-green-500 text-white rounded-sm py-3 text-sm tracking-wide transform motion-safe:hover:-translate-y-1 text-blue-700 motion-safe:hover:scale-110 transition ease-in-out"
                            >
                              Pesquisar
                            </LoadingButton>
                          </form>
                        </div>
                        <div class="mx-auto py-4">
                          <table class="table-auto text-xs">
                            <thead>
                              <tr class="text-gray-600 bg-gray-300">
                                <th class="border px-4 py-2">Cód barra</th>
                                <th class="border px-4 py-2">Produto</th>
                                <th class="border px-4 py-2">Estoque</th>
                                <th class="border px-4 py-2">Ação</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                class="text-gray-500 bg-gray-100"
                                v-for="listStock in listStocks"
                                :key="listStock"
                              >
                                <td>{{ listStock.codigo_barra }}</td>
                                <td>{{ listStock.nome_produto }}</td>
                                <td>{{ listStock.estoque }}</td>
                                <td>
                                  <div
                                    class="block w-full bg-green-500 text-white rounded-sm py-3 text-sm tracking-wide transform motion-safe:hover:-translate-y-1 text-blue-700 motion-safe:hover:scale-110 transition ease-in-out"
                                  >
                                    <SubmitFormButton
                                      btnText="Escolha"
                                      @click="selectProduct(listStock)"
                                    />
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          <div class="flex items-center justify-center p-2">
                            <v-pagination
                              v-model="pageStocks"
                              :pages="pageCountStocks"
                              :range-size="1"
                              active-color="#337aff"
                              @update:modelValue="listStocks"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <vue-snotify />
                    <!--footer-->
                    <div
                      class="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b"
                    >
                      <button
                        class="text-red-500 bg-transparent border border-solid border-red-500 hover:bg-red-500 hover:text-white active:bg-red-600 font-bold uppercase text-sm px-6 py-3 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                        type="button"
                        v-on:click="toggleModalProduct()"
                      >
                        Sair
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div
                v-if="showModalProduct"
                class="opacity-25 fixed inset-0 z-40 bg-black"
              ></div>
              <button
                class="bg-gray-400 text-white active:bg-pink-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="button"
                v-on:click="toggleModalProductCB()"
              >
                Escolha o produto que pertence a entrada por código de barra!
              </button>
              <div
                v-if="showModalProductCB"
                class="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex"
              >
                <div class="relative w-auto my-6 mx-auto max-w-3xl">
                  <!--content-->
                  <div
                    class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none"
                  >
                    <!--header-->
                    <div
                      class="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t"
                    >
                      <h3 class="text-3xl font-semibold">Produto</h3>
                      <button
                        class="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                        v-on:click="toggleModalProductCB()"
                      >
                        <span
                          class="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none"
                        >
                          ×
                        </span>
                      </button>
                    </div>
                    <!--body-->
                    <div class="relative p-6 flex-auto">
                      <div class="flex mt-4 mb-8">
                        <div class="w-full md:w-1/2 px-3">
                          <TextInput
                            label="Digitar o código de barra"
                            placeholder="Pesquisar..."
                            v-model:input="searchStockCB"
                            inputType="text"
                          />
                        </div>
                        <div class="w-full md:w-1/2 px-3">
                          <form
                            @submit.prevent="onSubmit"
                            @click="getStockListProdCB"
                          >
                            <LoadingButton
                              :isLoading="isLoading"
                              class="block w-full bg-green-500 text-white rounded-sm py-3 text-sm tracking-wide transform motion-safe:hover:-translate-y-1 text-blue-700 motion-safe:hover:scale-110 transition ease-in-out"
                            >
                              Pesquisar
                            </LoadingButton>
                          </form>
                        </div>
                        <div class="mx-auto py-4">
                          <table class="table-auto text-xs">
                            <thead>
                              <tr class="text-gray-600 bg-gray-300">
                                <th class="border px-4 py-2">Cód barra</th>
                                <th class="border px-4 py-2">Produto</th>
                                <th class="border px-4 py-2">Estoque</th>
                                <th class="border px-4 py-2">Ação</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                class="text-gray-500 bg-gray-100"
                                v-for="listStock in listStocks"
                                :key="listStock"
                              >
                                <td>{{ listStock.codigo_barra }}</td>
                                <td>{{ listStock.nome_produto }}</td>
                                <td>{{ listStock.estoque }}</td>
                                <td>
                                  <div
                                    class="block w-full bg-green-500 text-white rounded-sm py-3 text-sm tracking-wide transform motion-safe:hover:-translate-y-1 text-blue-700 motion-safe:hover:scale-110 transition ease-in-out"
                                  >
                                    <SubmitFormButton
                                      btnText="Escolha"
                                      @click="selectProduct(listStock)"
                                    />
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          <div class="flex items-center justify-center p-2">
                            <v-pagination
                              v-model="pageStocks"
                              :pages="pageCountStocks"
                              :range-size="1"
                              active-color="#337aff"
                              @update:modelValue="listStocks"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <vue-snotify />
                    <!--footer-->
                    <div
                      class="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b"
                    >
                      <button
                        class="text-red-500 bg-transparent border border-solid border-red-500 hover:bg-red-500 hover:text-white active:bg-red-600 font-bold uppercase text-sm px-6 py-3 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                        type="button"
                        v-on:click="toggleModalProductCB()"
                      >
                        Sair
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div
                v-if="showModalProductCB"
                class="opacity-25 fixed inset-0 z-40 bg-black"
              ></div>

              <div
                class="w-full border px-4 py-2 rounded focus:border-blue-500 focus:shadow-outline outline-none"
              >
                <TextInput
                  label=""
                  placeholder=""
                  v-model:input="id_produto"
                  inputType="hidden"
                  readonly
                />
                <a class="text-sky-400 font-bold"
                  >Produto : {{ id_produto }}-{{ nome_produto }}</a
                >
              </div>
              <div
                class="w-full border px-4 py-2 rounded focus:border-blue-500 focus:shadow-outline outline-none"
              >
                <div class="w-full md:w-auto px-3">
                  <TextInput
                    label="Código barra"
                    placeholder="Código barra"
                    v-model:input="codigo_barra"
                    inputType="hidden"
                  />
                  {{ codigo_barra }}
                </div>
                <div class="w-full md:w-auto px-3">
                  <TextInput
                    label="Unidade"
                    placeholder="Unidade"
                    v-model:input="unidade"
                    inputType="hidden"
                  />
                  {{ unidade }}
                </div>
                <div class="w-full md:w-auto px-3">
                  <TextInput
                    label="Valor unitário"
                    placeholder="Valor unitário"
                    v-model:input="vlrunitario"
                    inputType="text"
                  />
                </div>
                <div class="w-full md:w-auto px-3">
                  <TextInput
                    label="Quantidade"
                    placeholder="Quantidade"
                    v-model:input="quantidade"
                    inputType="text"
                  />
                  <SubmitFormButton
                    btnText="Qtd + Vlr Unitátio"
                    @click="multiplicarprod()"
                  />
                </div>
                <div class="w-full text-gray-700 md:w-auto px-3">
                  <TextInput
                    label="Valor total"
                    placeholder="Valor total"
                    v-model:input="vlrtotal_item"
                    inputType="hidden"
                  />
                  {{ vlrtotal_item | formatNumber }}
                </div>
                <div class="flex flex-wrap mt-8 mb-6">
                  <div class="w-full md:w-auto px-6">
                    <form
                      @submit.prevent="onSubmit"
                      @click="registerItemSolicitacao"
                    >
                      <LoadingButton
                        :isLoading="isLoading"
                        class="block w-full bg-green-500 text-white rounded-sm py-3 text-sm tracking-wide transform motion-safe:hover:-translate-y-1 text-blue-700 motion-safe:hover:scale-110 transition ease-in-out"
                      >
                        Include(Incluir)
                      </LoadingButton>
                    </form>
                  </div>
                </div>
              </div>
              <div class="bg-green-500 w-full h-1"></div>
              <div class="text-gray-600 text-xl">Itens da solicitação</div>
              <div class="bg-green-500 w-full h-1"></div>
              <table class="table-auto text-xs">
                <thead>
                  <tr class="text-gray-600 bg-gray-300">
                    <th class="border px-4 py-2">ID</th>
                    <th class="border px-4 py-2">Código barras</th>
                    <th class="border px-4 py-2">Descrição</th>
                    <th class="border px-4 py-2">Quantidade</th>
                    <th class="border px-4 py-2">Vlr unitario</th>
                    <th class="border px-4 py-2">Vlr total</th>
                    <th class="border px-4 py-2">Ação</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    class="text-gray-500 bg-gray-100"
                    v-for="listSolicitItem in listSolicitItems"
                    :key="listSolicitItem"
                  >
                    <td>{{ listSolicitItem.id }}</td>
                    <td>{{ listSolicitItem.codigo_barra }}</td>
                    <td>{{ listSolicitItem.nome_produto }}</td>
                    <td>{{ listSolicitItem.quantidade }}</td>
                    <td>{{ listSolicitItem.valor_unitario }}</td>
                    <td>{{ listSolicitItem.valor_totalProduto }}</td>
                    <td>
                      <SubmitFormButton
                        btnText="Deletar"
                        class="underline text-red-500 hover:text-red-600"
                        @click="deleteItemSolitacao(listSolicitItem.id)"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
              <div class="bg-green-500 w-full h-1"></div>
              <div class="flex items-center justify-center p-2">
                <v-pagination
                  v-model="pageItems"
                  :pages="pageCountItems"
                  :range-size="1"
                  active-color="#337aff"
                  @update:modelValue="getSolicitItemList"
                />
              </div>
            </div>
            <div class="bg-green-500 w-full h-1"></div>
            <div class="flex flex-wrap mt-8 mb-6">
              <form @submit.prevent="onSubmit" @click="register">
                <LoadingButton
                  :isLoading="isLoading"
                  class="block w-full bg-green-500 text-white rounded-sm py-3 text-sm tracking-wide transform motion-safe:hover:-translate-y-1 text-blue-700 motion-safe:hover:scale-110 transition ease-in-out"
                >
                  Salvar venda
                </LoadingButton>
              </form>
              <vue-snotify />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { ref } from "vue";
import NavbarComponent from "@/components/Navbar.vue";
import SidebarComponent from "@/components/Sidebar.vue";
import AdmMenuComponent from "@/components/parcial/adm/AdmMenu.vue";
import RouterLinkButton from "@/components/global/RouterLinkButton.vue";
import TextInput from "@/components/global/TextInput.vue";
import TextInputDate from "@/components/global/TextInputDate.vue";
import LoadingButton from "@/components/global/LoadingButton.vue";
import TextInputMoeda from "@/components/global/TextInputMoeda.vue";
import SubmitFormButton from "@/components/global/SubmitFormButton.vue";
import { useRoute, useRouter } from "vue-router";
import { useUserStore } from "@/store/user-store";
import { useSupplierStore } from "@/store/solicitationsupplier-store";

export default {
  components: {
    NavbarComponent,
    SidebarComponent,
    AdmMenuComponent,
    RouterLinkButton,
    LoadingButton,
    TextInput,
    SubmitFormButton,
    TextInputDate,
    TextInputMoeda,
  },
  name: "regular-modal",
  data() {
    const userStore = useUserStore();
    const route = useRoute();
    const router = useRouter();
    const SupplierStore = useSupplierStore();

    const url = process.env.VUE_APP_API_URL;
    let hover = ref(false);
    let showModal = ref(false);
    let showModalCliente = ref(false);
    let showModalSupplier = ref(false);
    let showModalProduct = ref(false);
    let showModalProductCB = ref(false);

    let searchCompany = ref(null);
    let listCompanies = ref(null);
    let pageCompanies = ref(1);
    let pageCountCompanies = ref(null);

    let searchCliente = ref(null);
    let listClientes = ref(null);
    let pageClientes = ref(1);
    let pageCountClientes = ref(null);

    let errors = ref([]);

    return {
      date: new Date().getFullYear(),
      useradministrador: userStore.administrador,
      userid_company: userStore.id_empresa,
      id_responsavel: userStore.id,
      name_responsavel: userStore.full_name,
      SupplierStore: SupplierStore,
      route: route,
      router: router,
      url_api: url,
      hover: hover,
      showModal: showModal,
      showModalCliente: showModalCliente,
      showModalSupplier: showModalSupplier,
      showModalProduct: showModalProduct,
      showModalProductCB: showModalProductCB,
      searchCompany: searchCompany,
      listCompanies: listCompanies,
      pageCompanies: pageCompanies,
      pageCountCompanies: pageCountCompanies,
      searchCliente: searchCliente,
      listClientes: listClientes,
      pageClientes: pageClientes,
      pageCountClientes: pageCountClientes,
      errors: errors,
      error: errors,
      searchSupplier: ref(null),
      listSuppliers: ref(null),
      pageSuppliers: ref(1),
      pageCountSuppliers: ref(null),
      listSolicitItems: ref(null),
      pageItems: ref(null),
      pageCountItems: ref(null),
      id_empresa: ref(null),
      isLoading: ref(false),
      id_cliente: ref(null),
      razaoSocial: ref(null),
      id_clienteVenda: ref(null),
      clienteVenda: ref(null),
      id_fornecedor: ref(null),
      nome_fornecedor: ref(null),
      dt_emissao: new Date().toLocaleString("pt-BR", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      }),
      vlrtotal_solicitacao: ref(null),
      vlrtotal: ref(null),
      id_solicitacao: ref(null),
      quantidade: ref(null),
      vlrunitario: ref(null),
      vlrtotal_item: ref(null),
    };
  },
  methods: {
    async getSolicitationsupplier() {
      // Make a GET request to the API
      const response = await this.SupplierStore.fetchByIdSolicitationsupplier(
        this.route.params.id
      );
      this.id_solicitacao = this.SupplierStore.id || null;
      this.id_cliente = this.SupplierStore.id_cliente || null;
      this.razaoSocial = this.SupplierStore.razaoSocial || null;
      this.id_fornecedor = this.SupplierStore.id_fornecedor || null;
      this.nome_fornecedor = this.SupplierStore.nome_fornecedor || null;
      this.dt_emissao = this.SupplierStore.dt_emissao || null;
      this.dt_retirada = this.SupplierStore.dt_retirada || null;
      this.dt_entrega = this.SupplierStore.dt_entrega || null;
      this.vlrtotal = this.SupplierStore.valorTotalPedido || null;
      this.$snotify.info("Aguarde atualizando ... !");
    },
    getCompanyList() {
      // Make a GET request to the API
      axios
        .get(
          this.url_api +
            "/api/companysearch/" +
            this.searchCompany +
            "?page=" +
            this.pageCompanies
        )
        .then((response) => {
          // Handle the successful response
          // console.log(response.data);
          this.pageCountCompanies = response.data.page_count1;
          this.listCompanies = response.data.paginate1.data;
        })
        .catch((error) => {
          // Handle any errors
          console.error("Error fetching data:", error);
          this.$snotify.error("Verifique novamente !");
        });
    },
    getClienteList() {
      // Make a GET request to the API
      axios
        .get(
          this.url_api +
            "/api/userssearch/" +
            this.searchCliente +
            "?page=" +
            this.pageClientes
        )
        .then((response) => {
          // Handle the successful response
          console.log(response.data);
          this.pageCountClientes = response.data.page_count;
          this.listClientes = response.data.paginate.data;
        })
        .catch((error) => {
          // Handle any errors
          console.error("Error fetching data:", error);
          this.$snotify.error("Verifique novamente !");
        });
    },
    getSupplierList() {
      axios
        .get(
          this.url_api +
            "/api/suppliersearch/" +
            this.searchSupplier +
            "?page=" +
            this.pageSuppliers
        )
        .then((response) => {
          // Handle the successful response
          // console.log(response.data);
          this.pageCountSuppliers = response.data.page_count1;
          this.listSuppliers = response.data.paginate1.data;
        })
        .catch((error) => {
          // Handle any errors
          console.error("Error fetching data:", error);
          this.$snotify.error("Verifique novamente !");
        });
    },
    getStockListProd() {
      axios
        .get(
          this.url_api +
            "/api/stocksearch/" +
            this.id_cliente +
            "/" +
            this.searchStock +
            "?page=" +
            this.pageStocks
        )
        .then((response) => {
          // Handle the successful response
          // console.log(response.data);
          this.pageCountStocks = response.data.page_count;
          this.listStocks = response.data.paginate.data;
        })
        .catch((error) => {
          // Handle any errors
          console.error("Error fetching data:", error);
          this.$snotify.error("Verifique novamente !");
        });
    },
    getStockListProdCB() {
      axios
        .get(
          this.url_api +
            "/api/stocksearchCB/" +
            this.id_cliente +
            "/" +
            this.searchStockCB +
            "?page=" +
            this.pageStocks
        )
        .then((response) => {
          // Handle the successful response
          // console.log(response.data);
          this.pageCountStocks = response.data.page_count;
          this.listStocks = response.data.paginate.data;
        })
        .catch((error) => {
          // Handle any errors
          console.error("Error fetching data:", error);
          this.$snotify.error("Verifique novamente !");
        });
    },
    getSolicitItemList() {
      axios
        .get(
          this.url_api +
            "/api/salesoitemlist/" +
            this.id_solicitacao +
            "?page=" +
            this.pageItems
        )
        .then((response) => {
          // Handle the successful response
          //console.log(response.data);
          this.pageCountItems = response.data.page_countItem;
          this.listSolicitItems = response.data.paginateItem.data;
          this.vlrtotal = response.data.valorTotalPedido;
        })
        .catch((error) => {
          // Handle any errors
          console.error("Error fetching data:", error);
          this.$snotify.error("Verifique novamente !");
        });
    },
    toggleModal() {
      this.showModal = !this.showModal;
    },
    toggleModalCliente() {
      this.showModalCliente = !this.showModalCliente;
    },
    toggleModalSupplier() {
      this.showModalSupplier = !this.showModalSupplier;
    },
    toggleModalProduct() {
      this.showModalProduct = !this.showModalProduct;
    },
    toggleModalProductCB() {
      this.showModalProductCB = !this.showModalProductCB;
    },
    selectCompany(event) {
      this.id_cliente = event.id;
      this.razaoSocial = event.razaoSocial;
      if (this.userid_company !== this.id_cliente) {
        this.$snotify.error("Empresa não pertence a : " + this.razaoSocial);
        this.id_cliente = null;
        this.razaoSocial = null;
      } else {
        this.$snotify.warning("Escolheu a : " + this.razaoSocial);
      }
    },
    selectCliente(event) {
      this.id_clienteVenda = event.id;
      this.clienteVenda = event.full_name;
      this.$snotify.warning("Escolheu a : " + this.clienteVenda);
    },
    selectSupplier(event) {
      this.id_fornecedor = event.id;
      this.nome_fornecedor = event.razaoSocial;
      this.$snotify.warning("Escolheu a : " + this.nome_fornecedor);
    },
    selectProduct(event) {
      this.id_produto = event.id;
      this.nome_produto = event.nome_produto;
      this.codigo_barra = event.codigo_barra;
      this.descricao = event.nome_produto;
      this.unidade = event.unidade;
      this.vlrunitario = event.preco_venda;

      this.$snotify.warning("Escolheu a : " + this.nome_produto);
    },
    onSubmit() {
      this.isLoading = true;
      setTimeout(() => (this.isLoading = false), 1000);
    },
    multiplicarprod() {
      // alert(`Selecionou !`)
      this.vlrtotal_item = this.quantidade * this.vlrunitario;
      this.vlrtotal_solicitacao = this.vlrtotal + this.vlrtotal_item;
    },
    registerItemSolicitacao() {
      if (this.id_solicitacao == null) {
        this.$snotify.warning("Register solicitation (Registrar solicitação)");
      }

      if (this.dt_emissao == null) {
        this.$snotify.warning("Enter request date(Digitar data de emissão)");
      }

      if (this.codigo_barra == null) {
        this.$snotify.warning("Enter request product(Incluir produto)");
      }

      this.error = [];
      let data = new FormData();
      data.append("id_solicitacao", this.id_solicitacao || "");
      data.append("id_produto", this.id_produto || "");
      data.append("descricao", this.descricao || "");
      data.append("dt_emissao", this.dt_emissao || "");
      data.append("unidade", this.unidade || "");
      data.append("vlrunitario", this.vlrunitario || "");
      data.append("quantidade", this.quantidade || "");
      data.append("vlrtotal_item", this.vlrtotal_item || "");

      axios
        .post(this.url_api + "/api/salesoitem", {
          id_pedido_item: this.id_solicitacao,
          id_prod_item: this.id_produto,
          codigo_barra: this.codigo_barra,
          nome_produto: this.descricao,
          dt_emissao: this.dt_emissao,
          unidade: this.unidade,
          valor_unitario: this.vlrunitario,
          quantidade: this.quantidade,
          valor_totalProduto: this.vlrtotal_item,
        })

        .then(() => {
          // Handle the successful response
          // console.log(response.data);
          this.$snotify.success(
            "Product successfully included in the request(Produto incluido com sucesso na solicitação)"
          );
          this.getSolicitItemList();
        })
        .catch((error) => {
          // Handle any errors
          console.error("Error fetching data:", error);
          this.$snotify.error("Verifique, falta informação !");
        });
    },
    register() {
      // console.log('Dentro register')
      this.error = [];
      let data = new FormData();
      data.append("id_solicitacao", this.id_solicitacao || "");
      data.append("id_responsavel", this.id_responsavel || "");
      data.append("id_cliente", this.id_cliente || "");
      data.append("id_colaborador", this.id_colaborador || "");
      data.append("dt_emissao", this.dt_emissao || "");
      data.append("id_solicitacao", this.id_solicitacao || "");
      data.append("vlrtotal_solicitacao", this.vlrtotal_solicitacao || "");
      if (this.id_solicitacao) {
        console.log(
          "id_solicitacao exitir ! Estou dentro do register antes axios:",
          data
        );
        axios
          .put(this.url_api + "/api/salesorder/" + this.id_solicitacao, {
            id_responsavel: this.id_responsavel,
            id_cliente: this.id_cliente,
            id_colaborador: this.id_colaborador,
            dt_emissao: this.dt_emissao,
            valorTotalPedido: this.vlrtotal_solicitacao,
          })
          .then((resRegister) => {
            console.log(
              "id_solicitacao exitir ! Estou dentro do register then:",
              resRegister
            );
            this.$snotify.success("Salvo com sucesso !");
          })
          .catch((error) => {
            //this.errors = error.response.data.errors;
            console.error("Error /api/salesorder/:", error);
            this.$snotify.error("Verificar Empresa ou data de emissão!");
            return error;
          });
      } else {
        // console.log(
        //   "id_solicitacao não existir !Estou dentro do register:",
        //   data
        // );
        axios
          .post(this.url_api + "/api/salesorder", {
            id_responsavel: this.id_responsavel,
            id_cliente: this.id_cliente,
            id_colaborador: this.id_colaborador,
            dt_emissao: this.dt_emissao,
          })
          .then((resRegister) => {
            this.$snotify.success("Salvo com sucesso !");
            this.id_solicitacao = resRegister.data.salesorder.id;
          })
          .catch((error) => {
            // Handle any errors
            // this.errors = error.data.error;
            //console.error("Error salesorderentry:", this.error);
            this.$snotify.error("Verificar Empresa ou data de emissão!");
            return error;
          });
      }
    },
    onPageChange() {
      this.getCompanyList();
      this.getSupplierList();
      //this.getSolicitationsupplier();
      this.getSolicitItemList();
      this.getStockListProd();
      this.getStockListProdCB();
      this.register();
    },
  },
  mounted() {
    //this.getSolicitationsupplier();
    this.getSolicitItemList();
  },
};
</script>
