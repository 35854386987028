<template>
  <div :class="['chat-container', { dark: isDarkMode }]">
    <h1 class="text-xl font-bold mb-4">Chat Application</h1>

    <div class="messages" ref="messagesEnd">
      <div
        v-for="msg in messages"
        :key="msg.id"
        class="message p-2 my-2 bg-gray-200 dark:bg-gray-600 rounded"
      >
        <strong>{{ msg.user }}:</strong> {{ msg.text }}
      </div>
    </div>

    <input
      v-model="newMessage"
      @keyup.enter="sendMessage"
      class="border border-gray-300 dark:border-gray-700 rounded p-2 w-full"
      placeholder="Type a message..."
    />
    <button
      @click="sendMessage"
      class="mt-2 p-2 bg-blue-500 hover:bg-blue-600 text-white rounded transition-all duration-300"
    >
      Send
    </button>

    <button
      @click="toggleDarkMode"
      class="mt-2 p-2 bg-gray-700 hover:bg-gray-800 text-white rounded transition-all duration-300"
    >
      Toggle Dark Mode
    </button>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      messages: [],
      newMessage: "",
      isDarkMode: false,
    };
  },
  methods: {
    async sendMessage() {
      if (this.newMessage.trim() === "") return;

      // Here you would have the API URL to send the message
      await axios.post("https://example.com/api/messages", {
        text: this.newMessage,
        user: "User1", // replace with actual user data
      });

      // For demonstration purposes, we will simply push the message locally
      this.messages.push({
        id: Date.now(),
        user: "User1",
        text: this.newMessage,
      });
      this.newMessage = "";

      // Scroll to the bottom of the chat
      this.$nextTick(() => {
        const messagesEnd = this.$refs.messagesEnd;
        messagesEnd.scrollTop = messagesEnd.scrollHeight;
      });
    },
    toggleDarkMode() {
      this.isDarkMode = !this.isDarkMode;
    },
  },
};
</script>

<style scoped>
.chat-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
.messages {
  flex-grow: 1;
  overflow-y: auto;
  max-height: 300px;
}
</style>
