import axios from "axios";
import { defineStore } from "pinia";

const url = process.env.VUE_APP_API_URL;

let url_api = url;

export const useStocksStore = defineStore("stocks", {
  state: () => ({
    id: null,
    id_cliente: null,
    razaoSocial: null,
    id_fornecedor: null,
    id_responsavel: null,
    name_responsavel: null,
    codigo_barra: null,
    nome_produto: null,
    categoria: null,
    marca: null,
    unidade: null,
    local: null,
    suporte: null,
    estoque: null,
    estoque_minimo: null,
    imposto: null,
    preco_custo: null,
    preco_venda: null,
    preco_venda: null,
    preco_lucro: null,
    ca: null,
    dt_vencimento: null,
    dt_hidrostatico: null,
    cod_conta: null,
    deleted_at: null,
  }),
  actions: {
    setStockIdDetails(res) {
      //Busca do banco de dados
      //console.log("setStockIdDetails", res.data);
      this.$state.id = res.data.id;
      this.$state.id_fornecedor = res.data.id_fornecedor;
      this.$state.codigo_barra = res.data.codigo_barra;
      this.$state.nome_produto = res.data.nome_produto;
      this.$state.categoria = res.data.categoria;
      this.$state.marca = res.data.marca;
      this.$state.unidade = res.data.unidade;
      this.$state.local = res.data.local;
      this.$state.suporte = res.data.suporte;
      this.$state.estoque = res.data.estoque;
      this.$state.estoque_minimo = res.data.estoque_minimo;
      this.$state.imposto = res.data.imposto;
      this.$state.preco_custo = res.data.preco_custo;
      this.$state.preco_venda = res.data.preco_venda;
      this.$state.preco_lucro = res.data.preco_lucro;
      this.$state.ca = res.data.ca;
      this.$state.dt_vencimento = res.data.dt_vencimento;
      this.$state.dt_hidrostatico = res.data.dt_hidrostatico;
      this.$state.cod_conta = res.data.cod_conta;
      this.$state.deleted_at = res.data.deleted_at;
      this.$state.id_responsavel = res.data.id_responsavel;
      this.$state.name_responsavel = res.data.user.name;
      this.$state.id_cliente = res.data.id_cliente;
      this.$state.razaoSocial = res.data.razaoSocial;
    },

    async fetchByIdStock(id) {
      let res = await axios.get(url_api + "/api/stock/" + id);
      // console.log(res.data);
      this.$state.id = res.data.stock.id;
      this.$state.codigo_barra = res.data.stock.codigo_barra;
      this.$state.nome_produto = res.data.stock.nome_produto;
      this.$state.categoria = res.data.stock.categoria;
      this.$state.marca = res.data.stock.marca;
      this.$state.unidade = res.data.stock.unidade;
      this.$state.local = res.data.stock.local;
      this.$state.suporte = res.data.stock.suporte;
      this.$state.estoque = res.data.stock.estoque;
      this.$state.estoque_minimo = res.data.stock.estoque_minimo;
      this.$state.imposto = res.data.stock.imposto;
      this.$state.preco_custo = res.data.stock.preco_custo;
      this.$state.preco_venda = res.data.stock.preco_venda;
      this.$state.preco_lucro = res.data.stock.preco_lucro;
      this.$state.ca = res.data.stock.ca;
      this.$state.dt_vencimento = res.data.stock.dt_vencimento;
      this.$state.dt_hidrostatico = res.data.stock.dt_hidrostatico;
      this.$state.cod_conta = res.data.stock.cod_conta;
      this.$state.deleted_at = res.data.stock.deleted_at;
      if (res.data.stock.user) {
        this.$state.id_responsavel = res.data.stock.id_responsavel;
        this.$state.name_responsavel = res.data.stock.user.name;
      } else {
        this.$state.id_responsavel = null;
        this.$state.name_responsavel = null;
      }
      if (res.data.stock.company) {
        this.$state.id_cliente = res.data.stock.id_cliente;
        this.$state.razaoSocial = res.data.stock.company.razaoSocial;
      } else {
        this.$state.id_cliente = null;
        this.$state.razaoSocial = null;
      }
    },

    clearIdStock() {
      this.$state.id = null;
      this.$state.id_fornecedor = null;
      this.$state.codigo_barra = null;
      this.$state.nome_produto = null;
      this.$state.categoria = null;
      this.$state.marca = null;
      this.$state.unidade = null;
      this.$state.local = null;
      this.$state.suporte = null;
      this.$state.estoque = null;
      this.$state.estoque_minimo = null;
      this.$state.imposto = null;
      this.$state.preco_custo = null;
      this.$state.preco_venda = null;
      this.$state.preco_lucro = null;
      this.$state.ca = null;
      this.$state.dt_vencimento = null;
      this.$state.dt_hidrostatico = null;
      this.$state.cod_conta = null;
      this.$state.deleted_at = null;
      this.$state.id_responsavel = null;
      this.$state.name_responsavel = null;
      this.$state.id_cliente = null;
      this.$state.razaoSocial = null;
    },
  },
  persist: true,
});
