/* eslint-disable no-console */

import { register } from "register-service-worker";

// generate /service-worker.js in production mode
// if (process.env.NODE_ENV === "production") {
//   // Default to GenerateSW mode, though InjectManifest also might be used.
//   const workboxPluginMode = userOptions.workboxPluginMode || "GenerateSW";
//   const workboxWebpackModule = require("workbox-webpack-plugin");

//   if (!(workboxPluginMode in workboxWebpackModule)) {
//     throw new Error(
//       `${workboxPluginMode} is not a supported Workbox webpack plugin mode. ` +
//         `Valid modes are: ${Object.keys(workboxWebpackModule).join(", ")}`
//     );
//   }

const defaultOptions = {
  exclude: [/\.map$/, /img\/icons\//, /favicon\.ico$/, /^manifest.*\.js?$/],
};

if (process.env.NODE_ENV === "production") {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready() {
      console.log(
        "App is being served from cache by a service worker.\n" +
          "For more details, visit https://goo.gl/AFskqB"
      );
    },
    registered() {
      console.log("Service worker has been registered.");
    },
    cached() {
      console.log("Content has been cached for offline use.");
    },
    updatefound() {
      console.log("New content is downloading.");
    },
    updated() {
      console.log("New content is available; please refresh.");
    },
    offline() {
      console.log(
        "No internet connection found. App is running in offline mode."
      );
    },
    error(error) {
      console.error("Error during service worker registration:", error);
    },
  });
}
